import './App.css';
import React, { useEffect, useContext } from 'react';
import Home from './Home.js';
import Signin from './Signin.js';
import { postData } from '../helpers/index.js'
import { SessionContext } from '../reducers/index.js'

function App() {
  const { user, dispatch } = useContext(SessionContext);

  let credentialResponse = (response) => {
    if (response.credential) {
      const options = {
        'credential': response['credential'],
        'select_by': response['select_by']
      }

      const headers = {
        'Content-Type': 'application/json'
      }

      const data = postData('auth', options, headers)
      data.then(resp => {
        if (resp.status === 200) {

          dispatch({
            type: 'SET_USER', payload: {
              'user': {
                'name': resp.data[0]['name'],
                'image': resp.data[0]['image'],
                'email': resp.data[0]['email'],
                'credential': resp.data[0]['credential'],
              }
            }
          });
        }
      })
    }
  }

  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id: "955028599690-5h2k8sqrvmn92j4k6scv3ndi0j89kh50.apps.googleusercontent.com",
      auto_select: true,
      callback: credentialResponse
    })

    window.google.accounts.id.renderButton(
      document.getElementById("googleSignIn"),
      { theme: "outline", size: "large" })

    window.google.accounts.id.prompt(); // also display the One Tap dialog
  }, []);

  return (
    <div >
      <nav>
        {user && user.name ? <Home /> : <Signin />}
      </nav>
    </div>
  );
}

export default App;
