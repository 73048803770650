import { createContext, useReducer } from "react";

export const SessionContext = createContext();


export const sessionReducer = (state, action) => {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state, ...action.payload
            };
        case 'SET_GROUPS':
            return {
                ...state,
                groups: {
                    ...state.groups, ...action.payload
                }
            };
        case 'SET_SINGLE_ITEM':
            return {
                ...state,
                singleItem: {
                    ...action.payload
                }
            };
        default:
            return state;
    }
};


export const SessionProvider = ({ children }) => {
    const [session, dispatch] = useReducer(sessionReducer, {
        user: {
            name: null,
            image: null,
            email: null,
            credential: null,
        },
        groups: {},
        singleItem: {},
    });

    const user = session.user;
    const group = session.groups;
    const singleItem = session.singleItem;

    return (
        <SessionContext.Provider value={{ user, group, singleItem, dispatch }}>
            {children}
        </SessionContext.Provider>
    );
}
