import React, { useState, useContext, useEffect } from 'react';
import './Feed.css';
import './Spinner.css';
import Items from './Items.js';
import { SessionContext } from '../reducers/index.js';
import { postData, getData } from '../helpers/index.js';
import Item from './Item.js';
import ErrorModal from './ErrorModal.js';

function Feed() {
    const { user, group, singleItem, dispatch } = useContext(SessionContext);

    const [addItem, setAddItem] = useState(true);
    const [userGroup, setUserGroup] = useState('');
    const [title, setTitle] = useState('');
    const [productName, setProductName] = useState('');
    const [price, setPrice] = useState('');
    const [url, setUrl] = useState('');
    const [carousel, setCarousel] = useState(false);
    const [items, setItems] = useState([]);
    const [returnedPrice, setReturnedPrice] = useState(0);
    const [index, setIndex] = useState(0);
    const [showSingleItem, setShowSingleItem] = useState(false);
    const [watchFeed, setWatchFeed] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [itemNotFound, setItemNotFound] = useState(false);



    const left = "<<";
    const right = ">>";


    const onLeftClick = () => {
        if (index === 0) {
            setIndex(items.length - 1);
        } else {
            setIndex(index - 1);
        }
    }

    const onRightClick = () => {
        if (index === items.length - 1) {
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
    }

    let showAddItem = () => {
        setCarousel(false);
        setAddItem(!addItem);
    }

    const onChangeGroup = (e) => {
        setUserGroup(e.target.value);
    }

    const onChangeTitle = (e) => {
        setTitle(e.target.value);
    }

    const onChangeProductName = (e) => {
        setProductName(e.target.value);
    }

    const onChangePrice = (e) => {
        setPrice(e.target.value);
    }

    const onChangeUrl = (e) => {
        setUrl(e.target.value);
    }

    const closeError = () => {
        setError(false);
    }

    const closeItemError = () => {
        setError(false);
        setItemNotFound(false);
    }

    const findItem = () => {
        setLoading(true);

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.credential,
            'Useremail': user.email,
        }

        const options = {
            'product_name': productName,
            'url': url,
        }

        const data = postData('item', options, headers)
        data.then(resp => {
            if (resp.data[1] === 200) {
                if (resp.data[0]['message'] === 'Item not Found') {
                    setError(true);
                    setItemNotFound(true);
                    setItems([]);
                } else {
                    setItems(prevItems => {
                        return resp.data[0]['images'];
                    })
                    setCarousel(true)
                    setReturnedPrice(resp.data[0]['price'])
                }
            } else {
                setError(true);

            }
            setLoading(false);
        }
        )


    }

    const onSaveItem = () => {
        setLoading(true);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.credential,
            'Useremail': user.email,
        }
        const options = {
            'group_id': userGroup,
            'title': title,
            'product_name': productName,
            'current_price': returnedPrice,
            'url': url,
            'price': price,
            'image': items[index],
        }

        const data = postData('create-item', options, headers)
        data.then(resp => {
            if (resp.status === 200) {
                showAddItem();
                dispatch({
                    type: 'SET_GROUPS', payload: resp.data[0]['groups']
                });
            } else {
                setError(true);
            }
            setTitle('');
            setProductName('');
            setPrice('');
            setUrl('');
            setLoading(false);
        })
    }

    const onClickSingleItemBack = () => {
        dispatch({
            type: 'SET_SINGLE_ITEM', payload: {}
        });
        setShowSingleItem(false);
        setWatchFeed(true);
    }

    useEffect(() => {
        if (userGroup === '' && group !== undefined && Object.keys(group).length > 0) {
            setUserGroup(Object.keys(group)[0]);
        }
        if (singleItem !== undefined && Object.keys(singleItem).length > 0) {
            setShowSingleItem(true);
            setWatchFeed(false);
        }
    }, [group, singleItem]);

    const options = Object.entries(group).map(([key, g]) => (
        <option key={key} value={key}>
            {g.name}
        </option>
    ));

    return (
        <>
            {
                error && !itemNotFound && (
                    <ErrorModal isOpen={error} close={closeError} message="An error has occurred." />
                )
            }
            {
                error && itemNotFound && (
                    <ErrorModal isOpen={error} close={closeItemError} message="Item not found." />
                )
            }
            {
                showSingleItem && !watchFeed && (
                    <div>
                        <div className='back-item-button' onClick={onClickSingleItemBack}>
                            Back
                        </div>

                        <Item gitem={singleItem.single_group}
                            item={group[singleItem.single_group].items[singleItem.single_item]} />
                    </div>
                )
            }
            {

                addItem && watchFeed && (

                    <div className='watch-feed'>
                        <div className='title-add'>
                            <h4>
                                Watch Feed
                            </h4>
                            <div className='add-item'>
                                <div className='add-item-button' onClick={showAddItem}>
                                    Add Item
                                </div>
                            </div>
                        </div>
                        <Items />
                    </div>
                )
            }
            {
                !addItem && watchFeed && (

                    <div className='add-item-form'>
                        <div className='add-item-form-title'>
                            <h4>
                                Add Item
                            </h4>
                        </div>
                        <div className='add-item-form-input'>
                            <div>
                                <label>
                                    Group
                                </label>
                                <select onChange={onChangeGroup} value={userGroup}>
                                    {options}
                                </select>
                            </div>
                            <div>
                                <label>
                                    Title
                                </label>
                                <input type='text' onChange={onChangeTitle} value={title} />
                            </div>
                            <div>
                                <label>
                                    Product Name
                                </label>
                                <input type='text' onChange={onChangeProductName} value={productName} />
                            </div>
                            <div>
                                <label>
                                    URL of product
                                </label>
                                <input type='text' onChange={onChangeUrl} value={url} />
                            </div>
                            <div>
                                <label>
                                    Price &lt;=
                                </label>
                                <input type='text' onChange={onChangePrice} value={price} />
                            </div>
                        </div>
                        <div className='add-item-form-buttons'>
                            <div className='add-item-button' onClick={showAddItem}>
                                Cancel
                            </div>
                            <button className='chimp-button' onClick={findItem} disabled={loading}>
                                {loading ? <div className='spinner'></div> : 'Find'}
                            </button>
                        </div>
                    </div>
                )
            }

            {
                carousel && watchFeed && (
                    <div className='item-details-server'>
                        {items && items.length > 0 && (
                            <>
                                <div className='first-carousel'>
                                    <div onClick={onLeftClick} className='carousel-left'>
                                        {left}
                                    </div>
                                    <img
                                        src={items[index]}
                                        alt='carousel'
                                        width={300}
                                        height={300} />
                                    <div onClick={onRightClick} className='carousel-right'>
                                        {right}
                                    </div>
                                </div>
                                <div style={{ fontSize: "15px", alignSelf: "center" }}>
                                    Number of images: {items.length}<br />
                                    Current image: {index + 1}
                                </div>
                            </>
                        )}
                        <p className='item-heading'>
                            {productName}
                        </p>
                        <div>

                            <p className='current-price'>
                                <b>Current Price:</b> {returnedPrice}
                            </p>
                            <p className='watch-for'>
                                <b>Price &lt;=</b>&nbsp;{price}
                            </p>
                        </div>
                        <div className='action-buttons' style={{ marginTop: "20px" }}>
                            <button className='chimp-button' onClick={onSaveItem} disabled={loading}>
                                {loading ? <div className='spinner'></div> : 'Save'}
                            </button>
                        </div>
                    </div>
                )
            }
        </>


    );
}

export default Feed;