import axios from 'axios'

// const BASE_URL = 'http://localhost:5500/'
const BASE_URL = 'https://test.api.chimpenzee.com/'

export const postData = (req, options={}, header={}) => {
    return axios.post(BASE_URL + req, JSON.stringify(options), {headers: header})
}

export const getData = (req, header={}) => {
    return axios.get(BASE_URL + req, {headers: header})
}

export const deleteData = (req, header={}) => {
    return axios.delete(BASE_URL + req, {headers: header})
}