import React from 'react';

function Signin() {
    return (
        <div className="App">
            <div className="App-header">
                <h1>Chimpenzee</h1>
                <nav>
                    <div id="googleSignIn"></div>
                </nav>
            </div>
        </div>
    );
}

export default Signin;