import React, { useContext, useState } from 'react';
import './GroupItems.css';
import { SessionContext } from '../reducers/index.js';

function GroupItems() {

    const { group, singleItem, dispatch } = useContext(SessionContext);


    let showGroup = (item, index) => {
        let node = document.getElementById(item);
        let parent = document.getElementById(item + "_" + index);

        let isHidden = node.style.display === 'none';
        if (isHidden) {
            node.style.display = 'block';
            parent.innerHTML = "- "
        }
        else {
            node.style.display = 'none';
            parent.innerHTML = "+ "
        }
    }

    const showSingleItem = (_group, _item) => {
        dispatch({
            type: 'SET_SINGLE_ITEM', payload: {
                'single_group': _group,
                'single_item': _item,
            }
        });
    }


    return (
        <div className='group-items'>
            <ul>
                {group && Object.keys(group).map((g, index) => {
                    return (
                        <li key={index}>
                            <div className="group-item" onClick={() => showGroup(g, index)}>
                                <span id={g + "_" + index}>+</span>&nbsp;
                                {group[g].name}
                            </div>
                            <ul id={g} className='group-sub-item'>
                                {group[g] && Object.keys(group[g].items).map((i, index) => {
                                    const item = group[g].items[i];
                                    return (
                                        <li key={index}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => showSingleItem(g, i)}>
                                            {item.title}
                                        </li>
                                    )
                                })}
                                {
                                    group[g] && Object.keys(group[g].items).length === 0 &&
                                    <li>
                                        No items
                                    </li>
                                }
                            </ul>
                        </li>
                    )
                }
                )}
            </ul>
        </div>
    );
}

export default GroupItems;