import React from 'react';
import './ErrorModal.css';
import './Group.css';

const Modal = ({ isOpen, close, message }) => {
  return (
    <div className={isOpen ? "modal-backdrop" : "modal-backdrop hidden"}>
      <div className="modal-content">
        <h2>Sorry, Something is not quite right</h2>
        <p>{message}</p>
        <button className='chimp-button'
          onClick={close}>Close</button>
      </div>
    </div>
  );
};

export default Modal;