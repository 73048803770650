import React, { useState, useEffect, useContext } from 'react';
import './Group.css';
import './Spinner.css';
import GroupItems from './GroupItems.js';
import { postData, getData } from '../helpers/index.js';
import { SessionContext } from '../reducers/index.js';
import ErrorModal from './ErrorModal.js';

function Group() {
    const [addGroup, setAddGroup] = useState(false);
    const [groupName, setGroupName] = useState('');
    const [groupDescription, setGroupDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const { user, dispatch } = useContext(SessionContext);

    let showAddGroup = () => {
        setAddGroup(!addGroup);
    }

    const logout = () => {
        dispatch({
            type: 'SET_USER', payload: {
                'user': {
                    'name': null,
                    'image': null,
                    'email': null,
                    'credential': null,
                }
            }
        });

        dispatch({
            type: 'SET_GROUPS', payload: {}
        });

    }
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const options = {
            'name': groupName,
            'description': groupDescription,
            'email': user.email,
        }

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.credential,
        }

        const data = postData('group', options, headers)

        data.then(resp => {
            if (resp.status === 200) {
                showAddGroup();
                dispatch({
                    type: 'SET_GROUPS', payload: resp.data[0]['groups']
                });

            } else {
                logout();
            }
            setLoading(false);
        })

    }

    const onNameChange = (e) => {
        setGroupName(e.target.value);
    }

    const onDescriptionChange = (e) => {
        setGroupDescription(e.target.value);
    }

    useEffect(() => {

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.credential,
            'Useremail': user.email,
        }

        const data = getData('group', headers)
        data.then(resp => {
            if (resp.status === 200) {
                const groups = resp.data[0]['groups'];
                dispatch({
                    type: 'SET_GROUPS', payload: groups
                });
            } else {
                logout();
            }
        })

    }, [])

    return (
        <div className='group-section'>
            <div className='group-heading'>

                <div className='g-title'>
                    <b>Your Groups</b>
                </div>
                <div className='g-add'>
                    <div className='add-item'>
                        <div className='add-item-group' onClick={showAddGroup}>
                            Add group
                        </div>
                    </div>
                </div>
            </div>
            {
                addGroup ?
                    <div>
                        <div className='add-group-form'>
                            <div className='add-group-form-title'>
                                <h4>
                                    Add Group
                                </h4>
                            </div>
                            <div className='add-group-form-input'>
                                <input className='chimp-input'
                                    type='text'
                                    placeholder='Group Name'
                                    onChange={onNameChange} />
                                <textarea className='chimp-description'
                                    type='text'
                                    onChange={onDescriptionChange}
                                    placeholder='Group Description' multiple />
                            </div>
                            <div className='add-item-form-buttons'>
                                <button className='chimp-button'
                                    onClick={onSubmit}
                                    disabled={loading}>
                                    {loading ? <div className='spinner'></div> : 'Submit'}
                                </button>
                                <div className='add-item-button' onClick={showAddGroup}>
                                    Cancel
                                </div>
                            </div>
                        </div>
                    </div>
                    : <GroupItems />
            }
        </div>
    );
}

export default Group;