import React, { useContext, useState } from 'react';
import { SessionContext } from '../reducers/index.js';
import { postData, deleteData } from '../helpers/index.js';
import './Items.css';
import './Spinner.css';
import ErrorModal from './ErrorModal.js';


function Item(props) {
    const [edit, setEdit] = useState(false);
    const { user, group, dispatch } = useContext(SessionContext);
    let { item, gitem } = props;
    if (!item) {
        item = {
            'product_name': '',
            'user_price': '',
        }
    }
    const [nameChange, setNameChange] = useState(item.product_name);
    const [priceChange, setPriceChange] = useState(item.user_price);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    let showEdit = () => {
        setEdit(!edit);
    }

    let onCancelEdit = () => {
        setEdit(false);
        setNameChange(item.product_name);
        setPriceChange(item.user_price);
    }

    const onProductNameChange = (value) => {
        setNameChange(value);
    }

    const onPriceChange = (value) => {
        setPriceChange(value);
    }

    const closeError = () => {
        setError(false);
    }

    const onEditItem = (gitem, id) => {
        if (nameChange === item.product_name && priceChange === item.user_price) {
            showEdit();
        } else {
            setLoading(true);
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.credential,
                'Useremail': user.email,
            }
            let options = {
                'product_name': nameChange,
                'price': priceChange,
                'group': gitem,
                'item_id': id,
            }
            const data = postData('edit-item', options, headers)
            data.then(resp => {
                if (resp.status === 200) {
                    showEdit();
                } else {
                    setError(true);
                }

                setLoading(false);
            })
            const newGroup = { ...group };
            const itemToChange = newGroup[gitem];
            const _item = itemToChange.items[id];
            if (itemToChange) {
                _item.product_name = nameChange;
                _item.user_price = priceChange;
                dispatch({
                    type: 'SET_GROUPS', payload: newGroup
                });
            }

        }
    }

    const onDelete = (gitem, id) => {
        setLoading(true);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.credential,
            'Useremail': user.email,
        }
        const data = deleteData('item/' + id + "/group/" + gitem, headers)
        data.then(resp => {
            if (resp.status === 200) {
                let newGroup = { ...group };
                delete newGroup[gitem].items[id];
                dispatch({
                    type: 'SET_GROUPS', payload: newGroup
                });
            } else {
                setError(true);
            }
            setLoading(false);
        })
    }

    return (
        <>
            {
                error && <ErrorModal isOpen={error} close={closeError} message="An error has occurred." />
            }
            {
                item.product_name === '' && (
                    <div className='item-details'>
                        <p className='item-heading'>
                            Item Deleted
                        </p>
                    </div>
                )
            }
            {
                item.product_name !== '' && (

                    <div className='items'>
                        <div className='item-image'>
                            <img src={item['image']}
                                alt='item'
                                width={300}
                                height={300} />
                        </div>
                        {
                            !edit && (
                                <div className='item-details'>
                                    <p className='item-heading'>
                                        {item.title}
                                    </p>
                                    <div>
                                        <p className='item-description'>
                                            {item.product_name}
                                        </p>
                                        <p className='current-price'>
                                            <b>Current Price:</b> {item.current_price}
                                        </p>
                                        <p className='watch-for'>
                                            <b>Price &lt;=</b>&nbsp;{item.user_price}
                                        </p>
                                    </div>
                                    <div className='action-buttons'>
                                        <div className='button' onClick={showEdit}>
                                            Edit
                                        </div>
                                        <button className='button' style={{ backgroundColor: "#282c34", color: "white" }} onClick={() => onDelete(gitem, item.id)} disabled={loading}>
                                            {loading ? <div className='spinner'></div> : 'Delete'}
                                        </button>
                                        <div className='button'>
                                            Share
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            edit && (
                                <div className='item-details'>
                                    <p className='item-heading'>
                                        {item.title} - Editing
                                    </p>
                                    <div>
                                        <p className='item-description-edit'>
                                            <textarea value={nameChange}
                                                onChange={(e) => onProductNameChange(e.target.value)} />
                                        </p>
                                        <p className='current-price'>
                                            <b>Current Price:</b> {item.current_price}
                                        </p>
                                        <p className='watch-for-edit'>
                                            <b>Price &lt;=</b>&nbsp;
                                            <input type='text' value={priceChange}
                                                onChange={(e) => onPriceChange(e.target.value)} />
                                        </p>
                                    </div>
                                    <div className='action-buttons'>
                                        <button className='button' style={{ backgroundColor: "#282c34", color: "white" }} onClick={() => onEditItem(gitem, item.id)} disabled={loading}>
                                            {loading ? <div className='spinner'></div> : 'Save'}
                                        </button>
                                        <div className='button' onClick={onCancelEdit}>
                                            Cancel
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </>
    );
}

export default Item;