import React, { useState, useContext, useEffect } from 'react';
import './Items.css';
import { SessionContext } from '../reducers/index.js';
import Item from './Item.js';



function Items() {
    
    const { group } = useContext(SessionContext);
    const [hasItems, setHasItems] = useState(false);
    

    useEffect(() => {
        let hasItems = false;
        if (group) {
            Object.keys(group).map((gitem, index) => {
                if (Object.keys(group[gitem].items).length > 0) {
                    hasItems = true;
                }
            }
            )
        }
        setHasItems(hasItems);

    }, [group])

    return (
        <>
            {
                !hasItems && (
                    <div className='no-items'>
                        No items to show
                    </div>
                )
            }

            {
                group && Object.keys(group).map((gitem, index) => {
                    return group[gitem] && Object.keys(group[gitem].items).map((item, index) => {
                        item = group[gitem].items[item];
                        return (
                            <Item key={index} item={item} gitem={gitem} />
                        )
                    })
                }
                )
            }
        </>
    );
}

export default Items;