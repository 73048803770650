import React, { useContext } from 'react';
import './Home.css';
import Feed from './Feed.js';
import Group from './Group.js';
import { SessionContext } from '../reducers/index.js';

function Home(props) {

    const { user } = useContext(SessionContext);

    return (

        <div className='home-page'>
            <div>

                <div className='nav-bar'>
                    <div className='header'>
                        Chimpenzee
                    </div>
                    <div className='user-options'>
                        <span>Hello {user.name}!</span>
                        <img src={user.image} alt='user' width={50} height={50} />
                    </div>
                </div>
            </div>
            <div>
                <div className='content'>
                    <div className='group'>
                        <Group />
                    </div>
                    <div className='feed'>
                        <Feed />

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;